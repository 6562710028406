import React, {Component} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import './styles/App.css';
import CoverHandler from "./CoverHandler";
import Fejlec from "./page_components/header";
import Uncalssified from "./page_components/404";
import {
  AdniOrom,
  HalottOtletekEjszakaja,
  TheRealMannequinChallenge, 
  ASzurkoloBennedVan,
  IttAzIdo,
  InditsdUjraANapot,
  NagyOKereso,
  EnjoySpar,
  AzEmberiTraffipax,
  YoungLions,
  NovellakVersek,
  SocialManInBicske,
  reduceFoodWaste } from './ProjectHandler';

class App extends Component {
  render() {
    return (
        <div>
          {<Fejlec />}
          <Switch>
            <Route path="/munkaim" component={CoverHandler} />
            <Route path="/project/adniorom" component={AdniOrom} />
            <Route path="/project/halottotletekejszakaja" component={HalottOtletekEjszakaja} />
            <Route path="/project/therealmannequinchallenge" component={TheRealMannequinChallenge} />
            <Route path="/project/aszurkolobennedvan" component={ASzurkoloBennedVan} />
            <Route path="/project/ittazido" component={IttAzIdo} />
            <Route path="/project/inditsdujraanapot" component={InditsdUjraANapot} />
            <Route path="/project/nagyokereso" component={NagyOKereso} />
            <Route path="/project/enyojspar" component={EnjoySpar} />
            <Route path="/project/azemberitraffipax" component={AzEmberiTraffipax} />
            <Route path="/project/younglions" component={YoungLions} />
            <Route path="/project/novellakversek" component={NovellakVersek} />
            <Route path="/project/socialmaninbicske" component={SocialManInBicske} />
            <Route path="/project/reducefoodwaste" component={reduceFoodWaste} />
            <Redirect from="/" to="/munkaim" exact />
            <Route component={Uncalssified} />
          </Switch>
        </div> 
    );
  }
}

export default App;