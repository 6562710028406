import React from 'react';
import { NavLink } from 'react-router-dom';

 export default function Fejlec() {
    return (
      <div class="container-fluid">
          <div class="navbar-header">
              <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#userfriendly">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
              <NavLink to="/"><div class="brand">RAJ DÁNIEL</div></NavLink>
          </div>
          <div class="collapse navbar-collapse" id="userfriendly">
            <ul class="nav navbar-nav navbar-right">
                <li><NavLink to="/munkaim">MUNKÁK</NavLink></li>
                <li><a href="https://www.linkedin.com/in/d%C3%A1niel-raj-a5427996/" target="_blank" rel="noopener noreferrer">SAY HI</a></li>
            </ul>
          </div>
      </div>
    );
}