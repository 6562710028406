const projectCovers = [
    { url: '/project/reducefoodwaste', cover: 'images/covers/strefowa_cover.png', title: 'Reduce Food ', t_bold: 'Waste' },
    { url: '/project/adniorom', cover: 'images/covers/adniorom.png', title: 'Az ország ', t_bold: 'Bevásárlólis - tája' },
    { url: '/project/halottotletekejszakaja', cover: 'images/covers/hoe.jpg', title: 'Halott Ötletek ', t_bold: 'Éjszakája' },
    { url: '/project/azemberitraffipax', cover: 'images/covers/traffipax.png', title: 'Az emberi ', t_bold: 'traffipax' },
    { url: '/project/aszurkolobennedvan', cover: 'images/covers/aszurkolobennedvan.png', title: 'A szurkoló benned ', t_bold: 'van' },
    { url: '/project/therealmannequinchallenge', cover: 'images/covers/mannequin.png', title: 'The Real Mannequin ', t_bold: 'Challange' },
    { url: '/project/ittazido', cover: 'images/covers/ittazido.png', title: 'Itt az ', t_bold: 'idő' },
    { url: '/project/socialmaninbicske', cover: 'images/covers/social_men_in_bicske.png', title: 'Social media ', t_bold: 'management' },
    { url: '/project/younglions', cover: 'images/covers/younglions.png', title: 'Young ', t_bold: 'lions', desc: 'Előválogató 2. helyezés' },
    { url: '/project/enyojspar', cover: 'images/covers/enjoy.png', title: 'Enjoy ', t_bold: 'SPAR' },
    { url: '/project/inditsdujraanapot', cover: 'images/covers/inditsdujraavarost.png', title: 'Indítsd újra a ', t_bold: 'napot!' },
    { url: '/project/nagyokereso', cover: 'images/covers/nagy_o_kereso.png', title: 'Nagy Ő ', t_bold: 'Kereső' },
    { url: '/project/novellakversek', cover: 'images/covers/thinking.png', title: 'Novellák, ', t_bold: 'versek' }
]

export default projectCovers;