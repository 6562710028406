import React, {Component, Fragment} from "react";
import projectCovers from "./Content";
import Footer from "./page_components/footer";
import {Link} from "react-router-dom";
import "./styles/projectonhover.css";


class CoverHandler extends Component {
  constructor() {
    super()
    this.state = { projectCovers };
  }

  projectgenerator (array) {
      let output = [];
      for (let index = 0; index < array.length; index++) {
        output[index] = (  
          <Link to={array[index].url}>
            <figure class="effect-onhover">
                    <img src={array[index].cover} alt={array[index].title} />
                    <figcaption>
                        <h2>{array[index].title}<span>{array[index].t_bold}</span></h2>
                        {array[index].desc === undefined ? "" : <p>{array[index].desc}</p>}
                    </figcaption>			
            </figure>
          </Link>
        );
      }
      return output;
  }

  render() {
    return (
      <Fragment>
        <div class="container menu">
                <div class="col-sm-12 grid">
                  {this.projectgenerator(this.state.projectCovers)}
                </div>
        </div>
        <Footer />
      </Fragment>
    );
  }

}

export default CoverHandler;