import React, {Fragment} from 'react';
import Footer from './page_components/footer';

//args: content col2, content col3, title, description, image or vid
function template(col2, col3, title, descr, other){
  return(
    <Fragment>
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="page-header">
              <h1>{title}</h1>      
            </div>
          </div>
        </div>
        <div class="row">
          {col2 === 0 ? "" : <div class={`col-sm-${col2}`}><p class="text-justify">{descr}</p></div>}
          {col3 === 0 ? "" : <div class={`col-sm-${col3}`}> {other} </div>}
        </div>
        <hr />
      </div>
      <Footer />     
    </Fragment>
  );
}

function video(code){
  return(
    <div class="embed-responsive embed-responsive-16by9">
      <iframe 
        title="video"
        class="embed-responsive-item" 
        src={`https://www.youtube.com/embed/${code}`} 
        allowfullscreen>
      </iframe>
    </div>
  );
}

function HalottOtletekEjszakaja(){
  return (
    <Fragment>
    {
      template(
        6,6,
        "Halott ötletek éjszakája - Greenroom", 
        <p>Vannak azok az ötletek, amikről az első pillanattól tudjuk, hogy zseniálisak.<br />Ezek azok az ötletek, amiktől már látjuk is, ahogy tapsvihar közepette átvesszük a színpadon a cannes-i oroszlánt. <br />Ám gyakran a díjeső és a fizetésemelés helyett csak elfojtott nevetés és kínos csend vár minket.<br />„Haha, persze, csak vicceltem” – mondjuk ilyenkor nevetve, miközben szemhéjunkat megfeszítve tartjuk vissza a szégyen könnyeit. <br />Nem élhetünk örökké halott ötleteink árnyékában. Ideje elengedni őket.</p>, 
        video("oVRoleoQ7gA")
      )
    };
    </Fragment>
  );
}
    
function AdniOrom(){
  return(
        <Fragment>
        {
          template(
            6,6,
            "Az Ország Bevásárlólistája - Adni Öröm - SPAR - Magyar Máltai Szeretetszolgálat", 
            <p>A SPAR és a Magyar Máltai Szeretetszolgálat már több mint 20 éve segíti a rászorulókat az Adni Öröm adománygyűjtő akcióval.<br />
            Az adománygyűjtő akciók java része azt kommunikálja, hány tonna adományra van szüksége. A legtöbben azonban ezzel nem tudnak mit kezdeni, nem tudják, az mennyinek felel meg és hogyan vehetnék ki pontosan a részüket. <br />
            Ezért úgy döntöttünk, az Adni Öröm 2018-as kampányában érzékletes és kézzelfogható módon mutatjuk be, mekkora adománymennyiségre van szükségünk…</p>, 
            video("jGELlF2NEVI")
          )
        };
      </Fragment>
      );
}
    
function TheRealMannequinChallenge(){
        return(
          <Fragment>
          {
            template(
              6,6,
              "The Real Mannequin Challenge - Budapest Bike Maffia", 
              <p>2017 egyik legnépszerűbb kihívása a Mannequin Challenge volt, amiben a szereplők „megfagyott” helyzeteket mutattak be.<br />A vicces meme jó lehetőséget adott nekünk arra, hogy valami sokkal komolyabbra hívjuk fel vele a figyelmet…</p>, 
              video("S4BRjxA9YFU")
            )
          };
        </Fragment>
        );
}
    
function ASzurkoloBennedVan(){
        return (
          <Fragment>
            {
              template(
                6,6,
                "A szurkoló benned van - MOL", 
                <p>Mindenkiben ott lakozik a szurkoló. <br /> Ott van velünk, amikor kedvenc csapatunk játszik, de akkor is, amikor valakit a busz után látunk futni. <br />És ott volt velünk, amikor a MOL 2016-os kampányának fókuszába állítottuk, hogy így mutassuk be a vállalat sporttámogatói szerepét.
                <br /><br />{video("h7NTUnPJqTo")}</p>, 
                <img src="/images/szbv_mese.jpg" class="img-thumbnail float-right" alt="" />
              )
            };
          </Fragment>  
        );
}
    
function IttAzIdo(){
        return (
          <Fragment>
            {
              template(
                6,6,
                "Itt az idő - MOL", 
                <p>2016-ot írunk, a magyar labdarúgó-válogatott 44 év után ismét játszik az Európa Bajnokságon. <br /> A MOL elkötelezett támogatója a hazai sportnak, így akkori kommunikációját is támogatói tevékenysége köré építette. <br />Az alábbi filmünk többek között egy ezüstöt is nyert a 2016-os Cannes Corporate Awards-on.</p>, 
                video("Y5SIJavqW-w")
              )
            };
          </Fragment>  
        );
}
    
function InditsdUjraANapot(){
        return (
          <Fragment>
            {
              template(
                6,6,
                "Indítsd újra a napot - Nescafé", 
                <p>Vannak azok a napok, amiken csak egy restart segíthet. Ebben segít a Nescafé 3in1 kávé, aminek 2015-ös nyereményjátékához egy egész várost is létrehoztunk…</p>, 
                video("M8aa0TtehJE")
              )
            };
          </Fragment>  
        );
}
    
function NagyOKereso(){
        return (
          <Fragment>
            {
              template(
                6,6,
                "Nagy Ő kereső - Samsung", 
                <p>Mindenkivel előfordult már, hogy szemezett valakivel a metrón vagy a buszon, de nem szólította le őt, így az örökre eltűnt életéből.<br />A Samsung Galaxy Note 4 újgenerációs érintőceruzájának, egy fantomrajzolónak és a facebookos követőtáborunknak köszönhetően azonban most egy új lehetőség adódik a szerelemre!</p>, 
                video("0lGYajfTbPk")
              )
            };
          </Fragment>  
        );
}
    
function EnjoySpar(){
        return (
          <Fragment>
            {
              template(
                6,6,
                "Enjoy Spar", 
                <p>A SPAR enjoy. kész finomságaival megspórolhatod az étel- és italkészítés idejét, így több időd juthat arra, ami igazán fontos neked.</p>, 
                video("LwA74wEj6IQ")
              )
            };
          </Fragment>  
        );
}
    
function AzEmberiTraffipax(){
        return (
          <Fragment>
            {
              template(
                6,6,
                "Az emberi traffipax - SPAR", 
                <p><strong>Feladat</strong><br />Mutassuk be, hogy a SPAR enjoy. szendvics a nagyvárosi, mindig rohanó ember legjobb választása, ha gyorsan akar finomat enni. <br /> <br /> <strong>Megoldás </strong> Létrehoztuk az első Emberi Traffipaxot, amivel bemértük az igazán siető járókelőket, így célzottan tudtuk őket meglepni azzal, amire a legnagyobb szükségük volt: egy finom reggelivel, amit jó eséllyel kihagytak.</p>, 
                video("w7orwO42dQk")
              )
            };
          </Fragment>  
        );
}

function reduceFoodWaste(){
  return (
    <Fragment>
      {
        template(
          6,6,
          "Reduce Food Waste – STREFOWA", 
          <p>A SPAR is részt vesz az Európai Unió által támogatott STREFOWA programban, melynek célja, hogy felhívja a vásárlók figyelmét a felesleges élelmiszerpazarlásra.<br />
          Kevesen tudják, hogy az üzletlánc polcsorain elöl kapnak helyet a korábbi lejáratú termékek, ezért nem őket veszik le.<br />
          Az élelmiszerek nem tudnak felszólalni magukért, ezért úgy döntöttünk, mi keltjük életre őket.</p>, 
          video("QX1y0oSDm_Q")
        )
      };
    </Fragment>  
  );
}
    
function YoungLions(){
        return (
          <Fragment>
            {
              template(
                12,0,
                "Young Lions", 
                <div align="center">
                  <img src="/images/project_young/ezgif-5-7a7d1db10a.pdf-01.png" class="img-thumbnail" alt="title"  />
                  <img src="/images/project_young/ezgif-5-7a7d1db10a.pdf-02.png" class="img-thumbnail" alt="task"  />
                  <img src="/images/project_young/ezgif-5-7a7d1db10a.pdf-03.png" class="img-thumbnail" alt="approach"  />
                  <img src="/images/project_young/ezgif-5-7a7d1db10a.pdf-04.png" class="img-thumbnail" alt="insight"  />
                  <img src="/images/project_young/ezgif-5-7a7d1db10a.pdf-05.png" class="img-thumbnail" alt="idea"  />
                  <img src="/images/project_young/ezgif-5-7a7d1db10a.pdf-06.png" class="img-thumbnail" alt="reference"  />
                  <img src="/images/project_young/ezgif-5-7a7d1db10a.pdf-07.png" class="img-thumbnail" alt="how it works"  />
                  <img src="/images/project_young/ezgif-5-7a7d1db10a.pdf-08.png" class="img-thumbnail" alt="in store"  />
                  <img src="/images/project_young/ezgif-5-7a7d1db10a.pdf-09.png" class="img-thumbnail" alt="444"  />
                  <img src="/images/project_young/ezgif-5-7a7d1db10a.pdf-10.png" class="img-thumbnail" alt="close dia"  />
                </div>
              )
            };
          </Fragment>
        );
}
    
function NovellakVersek(){
        return (
          <Fragment>
            {
              template(
                12,0,
                "Novellák, Versek", 
                <p>
                  Szokatlanul hideg volt szeptemberhez képest.<br />
                  A reggeli rádió szerint 70 éves rekord dőlt meg. A távolban lenyugvó nap méltósággal ragyogott mégis. Fénysugarai még egyszer, aznap utoljára simították végig a Golden Gate aranykapuit. A halk búcsút egy fiatal pár gyermekien gátlástalan nevetése törte meg. Petert rossz érzés fogta el, amint meglátta őket. Mikor egymás mellé értek, a lány lopva ránézett. Ijedtnek látszott.<br />
                  Talán megérezte, hogy mire készül – gondolta Peter, de mikor utánanézett, a lány figyelme már visszatért udvarlójára. <br />
                  Egy hideg széllökés csapta meg a férfit. Az autók egykedvűen hömpölyögtek az Amerikai Álom ezen 3 kilóméterén. Nem hatotta már meg a férfit ez a fogalom. Tudta jól, hogy itt is csak akkor lehet sikeres az ember, ha jó barátai vannak és minden jóbarát eltűnik, amint elhagyja a szerencse. 10 évig volt a BRC nevű japán cég Észak-Amerikai vezetője, szerette, szerették, jól éltek a családjával, míg egy tokiói hotelszobában készített videója miatt kirúgták. A botrány a szokásos forgatókönyvet követte: feljelentés, válás, láthatósági jog-vesztés, depresszió, alkohol. Mindenkire máshogy hat a kilátástalanság. Van, aki rombolni kezd, van, aki magát rombolja. Petert is az utóbbi hozta ide. <br />
                  Az elmúlt napokat ébren, agyalással töltötte. A megfejtés persze nem jött, így csak még szerencsétlenebbnek érezte magát. Nem bírta tovább. Átmászott a korláton és kihajolt a háborgó víz fölé. <br />
                  Más megtenni valamit, és más fantáziálni róla. Csak akkor dől el, jól döntöttünk-e, ha meghoztuk azt döntést. Hirtelen súlya lett tervének, amitől problémái is csak még dacosabban húzták lefelé. Régóta nem volt ilyen őszinte pillanata. Egészen eddig hazudott másoknak, de főleg magának: bűntudatról, válásról, arról, hogy jól bírja, arról, hogy kibírja. Remegve keresett magában olyan hangot, amelyik az élet mellett érvelt, de sehogy sem talált. Meglepődött, mikor elengedte a korlátot és zuhanni kezdett. <br />
                  Mi a faszt csinálok? – futott át rajta. <br />
                  A hullámok küzdeni kezdtek érte. Mindegyik azon volt, hogy elsőként ragadhassa el. A férfiban viszont nagyobb vihar dúlt. A halál bizonyossága a legerősebb életellenes indokot is kiűzte belőle. Kívülről látta magát és problémáit: Nincs élet, mit ne lehetne újrakezdeni. Nincs ember, kinek hiánya kimarhatja belőlünk a reményt. Nincs szégyen, aminek börtönlámpái elől ne lehetne elbújni. Minden bűnre van bocsánat, ha megadjuk magunknak. Nincs lejtő, ha nem döntünk úgy, hogy legyen. <br />
                  De visszaút sincs már. <br />
                  Nem akarok meghalni – üvöltötte egész teste miközben belezuhant hullámkoporsójába. Fent vakuk villogtak, a nap egybeolvadt a messzi tájjal. <br />
                  Feketeség. <br />
                  A legostobább elképzelés a halálról. A feketeség valami. A halálnak nincs színe, nincs tere. Peter most mégis feketeséget látott. Ez lenne a mennyország? Talán a pokol? Akárhogy is, hálát érzett, hogy volt tudata nem semmisült meg. <br />
                  Gyengéd női kéz ért arcához, ami levette a szemüveget a férfi fejéről.<br />
                  Kér egy pohár vizet? – kérdezte a nő.<br />
                  A férfi még kissé reszketve bólintott. A szoba körülötte egy fogorvosi rendelőre emlékeztette. <br />
                  Fehér bútorok, fehér falak, középen egy hatalmas kijelzőn felirat: VirtualTherapy – A kiút benned van!<br />
                  Megitta a vizet, felállt a kényelmes székből és felvette kabátját. <br />
                  Kint tud majd fizetni – szólalt meg újra a kisasszony. <br />
                  Alig negyed órája volt, hogy ivott a kezelés előtt, most mégis olyan szüksége volt rá, mintha egy hónapig lett volna a sivatagban. A recepciónál odaérintette óráját a terminálhoz, és gyorsan visszahúzta sötét ingjét, hogy az elfedje csuklóját. Rámosolygott a pult túloldalán ülő lányra, aki készségesen viszonozta a férfi gesztusát, üzenve: „Ne aggódj, nem láttam.” Mosolya gyenge és hazug volt, amatőrebb ahhoz képest, hogy napi 10 órában kellett újra és újra eljátszania.<br />
                  Peter kilépett a rendelőből. Az utcán épp a kaliforniai nap ereje őrjítette meg az embereket. Ideges járókelők, dudáló autók.  A szél érintése könnyet csalt a férfi szemébe. Érezte, hogy él, és hogy élni is akar. Úgy döntött, sétál hazafelé.  <br />
                </p>
              )
            };
          </Fragment>  
        );
}
    
function SocialManInBicske(){
        return (
          <Fragment>
            {
              template(
                12,12,
                "Social media", 
                <p>Szövegírói pályafutásom alatt valószínűleg több száz posztot írtam, és több ezer lájkot kaptam értük.<br />Persze ezt bármelyik tizenéves egy hét alatt utánam csinálja. <br /><br/>Főbb márkák, melyek social media jelenlétén dolgoztam:<ul><li> SPAR </li><li> Groupama </li><li> MOL </li><li> Budapest Bank </li><li> Samsung </li><li> Nescafé </li><li> Greenroom </li></ul></p>, 
                <div align="center">
                  <img src="/images/project_social/spar1.jpg" class="img-thumbnail" alt="SPAR"  />
                  <img src="/images/project_social/spar2.jpg" class="img-thumbnail" alt="SPAR"  />
                  <img src="/images/project_social/spar3.jpg" class="img-thumbnail" alt="SPAR"  />
                  <img src="/images/project_social/spar4.jpg" class="img-thumbnail" alt="SPAR"  />
                  <img src="/images/project_social/spar5.jpg" class="img-thumbnail float-left" alt="SPAR"  />
                  <img src="/images/project_social/spar6.jpg" class="img-thumbnail float-left" alt="SPAR"  />
                  <img src="/images/project_social/spar7.jpg" class="img-thumbnail float-left" alt="SPAR"  />
                  <img src="/images/project_social/spar8.jpg" class="img-thumbnail float-left" alt="SPAR"  />
                  <img src="/images/project_social/spar9.jpg" class="img-thumbnail float-left" alt="SPAR"  />
                  <img src="/images/project_social/groupama.jpg" class="img-thumbnail" alt="GRPOUPAMA"  />
                  <img src="/images/project_social/mol_freshhh1.jpg" class="img-thumbnail" alt="MOL"  />
                  <img src="/images/project_social/mol_freshhh2.jpg" class="img-thumbnail" alt="MOL"  />
                  <img src="/images/project_social/mol_freshhh5.jpg" class="img-thumbnail" alt="MOL"  />
                </div>
              )
            };
          </Fragment>  
        );
}

export { 
  AdniOrom,
  HalottOtletekEjszakaja,
  TheRealMannequinChallenge, 
  ASzurkoloBennedVan,
  IttAzIdo,
  InditsdUjraANapot,
  NagyOKereso,
  EnjoySpar,
  AzEmberiTraffipax,
  YoungLions,
  NovellakVersek,
  SocialManInBicske,
  reduceFoodWaste
} 