import React from "react";

function footer() {
    return (
        <div class="container">
            <div class="row">
                    <div class="col-sm-12" align="center">
                        <p className="footer">
                            Content by <a href="https://www.linkedin.com/in/d%C3%A1niel-raj-a5427996/" target="_blank" rel="noopener noreferrer">Raj Daniel</a><br />
                            Webpage is developed by <a href="https://szigus.net/?u=pages/contact" target="_blank" rel="noopener noreferrer">Gabor Sz.</a> 
                            {/* • Powered by <a href="https://szigus.net" target="_blank" rel="noopener noreferrer">szigus.net</a> */}
                        </p>
                    </div>
            </div>
        </div>
    );
}
export default footer;